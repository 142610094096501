.sidebar-layout-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1.5rem;

  .main-content {
    width: 75%;
    background: #fff;
    border-radius: 3px;
    border: 1px solid #f0f1f3;
  }
}
