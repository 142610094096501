@import './styles/fonts.css';

:root {
  --main-color: #6C63FF;
  --light-gray: #f2f2f2;
  --label-color: #535558;
  --title-color: #000000;
}

body {
  margin: 0;
  /* font-family: Hero-Regular; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: Hero-Regular;
}
