.signup-wrapper {
  .signup-inner {
    max-width: 1200px;
    margin: 0 auto;

    .signup-header {
      display: flex;
      padding: 20px 0;
      align-items: center;

      .signup-logo img {
        height: 28px;
      }
    }

    .signup-body {
      margin: 5rem 0 10rem 0;

      &__inner {
        display: flex;
        align-items: center;
        flex-direction: column;

        .signup-title {
          font-family: 'DM Sans', sans-serif;
          font-size: 48px;
          font-weight: 700;
          color: #152542;
          line-height: 1.3;
        }

        .signup-form {
          margin-top: 2rem;
          display: flex;
          flex-direction: column;
          background-color: #fff;
          box-shadow: 4px 6px 30px rgba(0, 0, 0, 0.12);
          border-radius: 5px;
          padding: 1.5rem;
          width: 400px;
          max-width: 100%;
          margin-bottom: 10rem;

          .input {
            background-color: #fff;
            border: 1px solid #dce4f4;
            border-radius: 3px;
            height: 38px !important;
            font-size: 1.5rem;
            outline: none;
            margin-top: 1rem;
            font-size: 12px;
            padding: 0 1rem;

            &:first-child {
              margin-top: 0;
            }
          }

          .company-logo-uploader {
            margin: 1rem 0 1rem 0;

            .company-logo-label {
              font-size: 14px;
              margin-bottom: 1rem;
              font-weight: bold;
            }

            .company-logo-uploader-inner {
              position: relative;
              margin-top: 0.5rem;

              .company-logo-field {
                outline: none;
                display: inline-block;
                width: 100%;
                padding: 150px 0 0 0;
                height: 100px;
                overflow: hidden;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
                background: url('https://cdn1.iconfinder.com/data/icons/hawcons/32/698394-icon-130-cloud-upload-512.png')
                  center center no-repeat #e4e4e4;
                border-radius: 3px;
                background-size: 60px 60px;
              }

              .company-logo-name {
                position: absolute;
                top: 70%;
                left: 50%;
                transform: translate(-50%, -50%);
              }
            }
          }

          .signup-checkbox{
            margin-bottom: 1.5rem;
          }

          .signup-error {
            color: crimson;
            margin: 0.5rem 0.5rem;
            font-size: 12px;
          }

          .submit-btn {
            background: #6c63ff;
            color: #fff;
            border-radius: 0.5rem;
            padding: 0.8rem 0;
            outline: none;
            opacity: 1;
            display: flex;
            justify-content: center;
            align-items: center;

            &:hover {
              background: #005aed;
            }

            &:disabled {
              opacity: 0.5;
            }

            .MuiCircularProgress-colorPrimary {
              color: #fff !important;
            }
            .button-loader {
              margin-left: 1rem;
            }
          }
        }
      }
    }
  }
}
