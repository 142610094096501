.optimization {
  margin-top: 3rem;

  &-title {
    color: var(--title-color);
    font-size: 24px;
    font-weight: 700;
    text-align: center;
  }

  .campForm {
    background-color: white;
    margin-top: 3rem;
    padding: 30px;

    &-inputs {
      width: 90%;
      display: flex;
      justify-content: space-between;
    }

    &-wrapper {
    }

    &-itembox {
      margin-bottom: 3rem;
    }

    &-double-itembox {
      display: flex;
      justify-content: space-between;
      margin-bottom: 3rem;

      .item-in-double {
        width: 48%;
        display: flex;
        flex-direction: column;
        // justify-content: flex-end;
      }
      .auto-cpm-check {
        justify-content: flex-end;
      }
    }
    &-three-itembox {
      display: flex;
      justify-content: space-between;
      margin: 3rem 0 2rem 0;

      .item-in-three {
        width: 32%;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
    &-input {
      .component-wrapper {
        padding: 1rem 1rem 1rem 2rem;
      }
      &-lable {
      }

      &-field {
        background: #ffffff;
        border: 1px solid #f0f1f3;
        box-sizing: border-box;
        border-radius: 2px;
        display: flex;
        align-items: center;
        padding: 6px 8px;
        margin-top: 8px;

        .MuiInput-root {
          width: 100%;
        }

        .MuiSvgIcon-root {
          fill: #6c63ff;
          width: 20px;
          height: 20px;
        }
        .MuiInput-underline:after {
          border-bottom-color: 'yellow';
        }
        .Mui-focused {
          outline: none;
          border: none;
        }
        .MuiInput-underline {
          &:focus {
            outline: none;
            border: none;
          }
          &:after {
            border: none;
          }
        }
        .MuiInput-underline:before {
          outline: none;
          border: none;
          content: '';
        }
        .MuiInput-underline:hover:not(.Mui-disabled):before {
          border-bottom: none;
        }
        .MuiIconButton-root {
          padding: 7px;
        }
        .calendar-icon {
          fill: #6c63ff;
        }
        .MuiInputBase-input {
          border: none;
          font-family: Hero-Bold;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 17px;
          color: #000000;

          &:focus {
            outline: none;
            border: none;
          }
          &::placeholder {
            font-family: Hero-Regular;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 17px;
            color: #b3b9c2;
          }
        }
      }
    }
  }
}

.custom-radio {
  width: 130% !important;
  padding: 15px !important;
}

.radio-option {
  width: 48% !important;
  padding-bottom: 15px !important;
}

.cross-button-wrapper {
  display: flex;
  align-items: center;

  .delete_icon_button {
    margin-top: 2rem;
  }
}
