.info-container {
  background-color: var(--light-gray);
  height: auto;
  min-height: 100vh;
  padding-bottom: 5rem;
}

.info-form {
  max-width: 496px;
  display: block;
  margin: 0 auto;
  box-sizing: border-box;
  margin-bottom: 5rem;
}
